@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:700");
$breakpoints: (
    'sp':'screen and (max-width: 767px)',
    'pc':'screen and (min-width: 768px)'
) !default;
@mixin bp($breakpoint: sp) {
    @media #{map-get($breakpoints,$breakpoint)} {
        @content;
    }
}
$base: #2849AE;
$base2: #00a0e9;
$txt-base:#333;
@mixin fnt-set{
font-feature-settings: "palt";
text-align: justify;
text-justify: inter-ideograph; 
}

@mixin fl {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_x {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
}
@mixin fl_y {
    display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_w {
    display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

@mixin rsp-img{
	width: 100%;
	height: auto;
}


// * hack IE
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver:'') {
    // * IE8 hack
    @if $hack-ver == "ie8" {
        @media \0screen {
            @content;
        }
    }
    // * IE9-10 hack(add propary \9)
    @else if $hack-ver == "ie9-10" {
        @media all and (min-width:0\0) {
            @content;
        }
    }
    // * IE10 hack(add propary \9)
    @else if $hack-ver == "ie10" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
    // * IE11 hack(add selector *::-ms-backdrop)
    @else if $hack-ver == "ie11" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
}
/*basic*/
.clearfix:after{content:".";display:block;clear:both;height:0;visibility:hidden;}.clearfix{min-height:1px;}.clear{clear:both;}
@include bp {
    .pc-s {display:none !important;}
}
@include bp(pc) {
    .sp-s {display:none !important;}
}

h1,h2,h3,h4,h5,h6{
	font-feature-settings: "palt";
}

.bg-theme{
background-color: $base;
}

h1#logo{
line-height: 1.4;
margin: 7px 0 0 0;

	@media (max-width: 520px) {
	margin: 15px 0 0 0;
	}

	@media (max-width: 340px) {
	margin: 17px 0 0 0;
	}


	a{
	font-family: "Noto Sans JP", sans-serif;
	color: black;
	font-size: 22px;

		small{
		display: block;
		font-size: 12px;
		}

	}

}/* h1#logo end */

.main-v-area{
background-image:url(../images/main-v.jpg) ;
background-size: cover;
background-repeat: no-repeat;
background-position: center bottom;
padding: 140px 0;
text-align: center;

	@media (max-width: 1140px) {
	padding: 50px 0;
	}

	.site-copy{
	margin: 0 0 140px;
	text-align: center;
	font-family: "Noto Sans JP", sans-serif;
	color: $base;
	font-size: 45px;
	letter-spacing: 2px;

		@media (max-width: 1140px) {
		margin: 0 0 50px;
		font-size: 30px;
		}

	}

	.row{
	max-width: 1140px;
	margin: 40px auto 0;

		@media (max-width: 1140px) {
		margin: 0;
		padding: 0 30px;
		}

		@media (max-width: 767px) {
		padding: 0 15px;
		}

		.topic-list{
		display: flex;
		flex-wrap:wrap;
		padding-left: 1%;

			@media (max-width: 991px) {
			justify-content: space-between;
			padding-left: 0;
			}

			&+a{
			display: none !important;
			}

			li{
			width: 31%;
			margin: 0 1% 2%;
			box-sizing: border-box;
			/* background: rgba(255,255,255,.6); */
			background: rgba(40,73,174,.7);
			padding:20px;

				@media (max-width: 991px) {
				width: 49%;
				margin: 0 0 2%;
				padding:10px;
				}

				@media (max-width: 767px) {
				width: 100%;
				}


				.day{
				font-size: 12px;
				font-weight: normal;
				color: white;
				display: block;
				margin: 5px;
				}

				h3{
				font-size: 18px;
				margin:0 0 5px;
				color: white;
				font-family: "Noto Sans JP", sans-serif;

					@media (max-width: 767px) {
					margin:0 0 3px;
					}

				}

				.price{
				text-align: center;
				font-size: 36px;
				font-weight: bold;
				line-height: 1.6;
				color: white;
				font-family: "Noto Sans JP", sans-serif;
				
					small{
					font-size: 18px;
					margin-left: 3px;
					}

				}

					&.item1{
					order: 0;
					}

					&.item2{
					order: 1;
					}

					&.item3{
					order: 2;
					}

					&.item4{
					order: 3;
					}

					&.item5{
					order: 4;
					}

					&.item6{
					order: 5;
					}


			}

		}/* .topic-list end */
		
	}/* .row end */
}/* .main-v-area end */

.no-style{
font-size: 25px;
font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
vertical-align: top;
display: table-cell;
padding-right: 15px;
line-height: 1;
color: $base2;
}

.sec-flow{

	h5{
	font-size: 16px;

		small{
		font-size: 80%;
		}

	}

}/* .sec-flow end */

.layer-overlay.overlay-dark-6::before{
background-color: rgba(17,17,17,.4);
}

.sec-machine{
background-position: center top;
background-size: cover;

	@media (max-width: 767px) {
	background-position: left top;
	}


	.num{
	
		&.mb{
		margin-bottom: 50px;

			@media (max-width: 767px) {
			margin-bottom: 30px;
			}

		}

		h3{
		color: white;
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 20px;

			@media (max-width: 767px) {
			font-size: 18px;
			}

		}

		table{
		border-collapse: collapse;
		width: 100%;

			th{
			border: 1px solid white;
			padding: 20px;
			color: white;
			font-size: 16px;
			/* background: rgba(0,0,0,.5); */
			background: rgba(40,73,174,.7);
			width: 80%;
			box-sizing: border-box;

				@media (max-width: 767px) {
				padding: 15px;
				font-size: 14px;
				}

			}

			td{
			border: 1px solid white;
			padding: 20px;
			color: white;
			font-size: 16px;
/* 			background: rgba(0,0,0,.5); */
			background: rgba(40,73,174,.7);
			text-align: center;

				@media (max-width: 767px) {
				padding: 15px;
				font-size: 14px;
				}

			}


		}

	}

}/* .sec-machine end */

table.base-tbl a{
color: $base2;

	&[href^="tel:"] {
	color: inherit;
	}
}



#footer.mt80{

	@media (max-width: 767px) {
	margin-top: 40px !important;
	}

}
